.uploaderImg {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 7px;
    position: relative;
    width: 100%;
}
.uploaderImg input {
    display: none;
}
.uploaderImg img, .uploaderImg .icon {
    pointer-events: none;
}
.uploaderImg, .uploaderImg .icon {
    transition: all 100ms ease-in;
}
.uploaderImg .icon {
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 150px;
    z-index: 1;
    top: 40% !important;
}
.uploaderImg img {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
}