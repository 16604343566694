
.load-texto {
    font-size: 2rem;
    color: white;
    margin-top: 2.5rem;
    font-weight: bold;
}

.cohorte-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width:  3.5rem;
    height: 3.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    animation: rotation infinite linear 1.5s;
}

.cohorte-icon {
    width:  2rem;
    height: 2rem;
    animation: rotation-reverse infinite linear 1.5s;

}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes rotation-reverse {
    from {
        -webkit-transform: rotate(359deg);
    }
    to {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes color {
    0%{
        color: #BE7D3C;
    }
    50%{
        color: #53A513;
    }
    100%{
        color: #BE7D3C;
    }
}
