.btn-blue{
    border: 0.13rem solid transparent;
    border-radius: 0.19rem;
    padding: 0.4rem 2rem;
    font-size: .75rem;
    line-height: 1.125;
    font-weight: bold;
    font-family: 'normal', Lato;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    color: #FFFFFF;
    background-color: #538BAF;
    border-color: #538BAF;
}

.minh-100 {
    height: 100vh;
  }